import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DownloadService } from '../services/download/download.service';
import { Filepdfxml } from '../interfaces/Filepdfxml/filepdfxml';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDialog } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS } from '../components/date-picker';
import { Documento } from '../entities/documento/documento';
import { SendService } from '../services/send.service';
import { EmailComponent } from '../email/email.component';

import Swal from 'sweetalert2';

interface Documentos {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})


export class HomeComponent implements OnInit {
  public files: Filepdfxml[];
  filteredOptions: any;
  loginForm: FormGroup;
  File: Documento = new Documento();
  mensaje: string;
  flagMail = false;

  /*Spinner*/
  mode = 'indeterminate';
  value = 50;
  color = 'primary';
  displayProgressSpinner: boolean;

  constructor(private formBuilder: FormBuilder,
    private apiDownload: DownloadService,
    private apiEmail: SendService,
    public dialog: MatDialog) { }


  docs: Documentos[] = [
    { value: '03', viewValue: 'Boleta' },
    { value: '01', viewValue: 'Factura' },
    { value: '07', viewValue: 'Nota de Crédito' },
    { value: '08', viewValue: 'Nota de Débito' },
    { value: '09', viewValue: 'Guia de Remisión - Remitente' },
    { value: '31', viewValue: 'Guia de Remisión - Transportista' },
    { value: '40', viewValue: 'Percepción' },
    { value: '00', viewValue: 'Detracción' }
  ];
  
  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      ruc: ['', Validators.required],
      ruc2: ['', Validators.required],
      serie: ['', Validators.required],
      numero: ['', Validators.required],
      documento: ['', Validators.required],
      date: ['', Validators.required]
    });


  }

  public errorHandling = (control: string, error: string) => {
    try {
      if (this.loginForm.get(control).value == "") {
        return this.loginForm.controls[control].hasError(error);
      }
    } catch {

    }

  }

  download(file: Filepdfxml) {

    this.apiDownload.downloadFile(file.id)
      .subscribe(res => {

        let blob: any = new Blob([res], { type: 'application/' + file.extension });

        const url = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = file.Nombre + "." + file.extension;
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(url);
          link.remove();
        }, 100);
        //window.open(url);
      });

  }

  search() {
    this.flagMail = false;
    this.File.convertirDate();
    try {
      if (this.File.getValidation()) {
        var name: string;
        var flag: boolean = true;

        if (this.File.documento == "03" && (this.File.rucReceptor == null || this.File.rucReceptor == undefined || this.File.rucReceptor.trim() == "")) {
          name = null;
          this.File.rucReceptor = "00000001";
        } else {
          if (this.File.rucReceptor != null || this.File.rucReceptor != undefined) {
            flag = true;
          } else {
            flag = false;
          }

        }

        name = this.File.getNameDoc();

        if (flag) {
          this.displayProgressSpinner = true;
          this.mensaje = null;
          this.apiDownload.searchFiles(name)
            .subscribe(res => {
              this.displayProgressSpinner = false;

              this.files = <Filepdfxml[]>res
              if (this.files.length > 0) {
                this.flagMail = true;

              } else {
                this.mensaje = "No existe el documento."
              }
            }, err => {
              this.displayProgressSpinner = false;
              this.mensaje = "No existe el documento."
            });
        } else {
          this.mensaje = "Datos incompletos.";
        }

      } else {
        this.mensaje = "Datos incompletos.";
      }

    } catch {
      console.log("Error");
    }


  }


  keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  clean() {

    this.File = new Documento();
    this.loginForm.setValue({
      ruc: [''],
      ruc2: [''],
      serie: [''],
      numero: [''],
      documento: [''],
      date: [this.File.date]
    });
    this.files = null;

  }

  openDialog() {
    const dialogRef = this.dialog.open(EmailComponent, {
      width: '300px',
      height: '180px',
      data: this.files
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.flag == true) {

        Swal.fire({
          position: 'center',
          icon: 'success',
          title: "Se envió correo satisfactoriamente",
          showConfirmButton: false,
          text: result.mensaje,
          showCloseButton: true
        })
       
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: "Error",
          showConfirmButton: false,
          text: result.mensaje,
          showCloseButton: true
        })
      }
    });
  }


  downloadCDR(file: Filepdfxml) {
    this.displayProgressSpinner = true;
    this.apiDownload.downloadCDR(file.Nombre)
      .subscribe(res => {
        this.displayProgressSpinner = false;
        console.log(res);
        if(res!=null){
          let blob: any = new Blob([res], { type: 'application/zip' });

          const url = window.URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = url;
          link.download = file.Nombre + ".zip";
          // this is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
  
          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(url);
            link.remove();
          }, 100);
        }else{
          alert("Error. Intente de nuevo");
        }
        
        //window.open(url);
      },err=>{
        this.displayProgressSpinner = false;
        alert("Error. Intente de nuevo");
      });

  }
}
