import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Filepdfxml } from '../interfaces/Filepdfxml/filepdfxml';
import { SendService } from '../services/send.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {

  mode = 'indeterminate';
  value = 50;
  color = 'primary';
  displayProgressSpinner: boolean;
  correoDestino:string;


  constructor(public dialogRef: MatDialogRef<EmailComponent>,
              @Inject(MAT_DIALOG_DATA) public data:Filepdfxml[],
              private apiEmail:SendService) { }

  ngOnInit() {
    
    console.log(this.displayProgressSpinner);
  }

  sendMail(){
    let correos:string [] = this.correoDestino.split(';')
    let mensaje:string = "";
    correos.forEach(element => {
      mensaje+=element+' ';
    });
    
    this.displayProgressSpinner=true;
    this.apiEmail.sendMail(this.data,this.correoDestino)
    .subscribe(res => {
      if(res=="1"){
        this.displayProgressSpinner=false;
        let respuesta = {
          flag:true,
          mensaje:"Correo(s): "+mensaje.trim()
        }
        this.dialogRef.close(respuesta);
      }
    },err=>{
      this.displayProgressSpinner=false;
      let respuesta = {
        flag:false,
        mensaje:"Ocurrió un error, intente de nuevo."
      }
      this.dialogRef.close(respuesta);


    });;
  }
}
