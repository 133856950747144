import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Filepdfxml } from '../interfaces/Filepdfxml/filepdfxml';

@Injectable({
  providedIn: 'root'
})
export class SendService {

  baseUrlString: string;
  constructor(private http:HttpClient) { 
    //this.baseUrlString="http://localhost:56589/Api/";
    this.baseUrlString="https://gdriveapi.posigesa.net.pe/Api/";
  }

  sendMail(files:Filepdfxml[],email){
		return this.http.post(this.baseUrlString+ "ApiEmail?correo="+email, files, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
}
