export class Documento {
    rucEmisor:string;
    rucReceptor:string;
    documento:string;
    serie:string;
    numero:string;
    date:Date;
    fechaDoc:string;

    public convertirDate():string{
        try{
            this.fechaDoc = this.date.getUTCFullYear().toString()+"-"
            +(this.date.getUTCMonth()+1).toString().padStart(2,'0')+"-"+this.date.getUTCDate().toString().padStart(2,'0');
            return null;
        }catch{
            return "Datos incompletos";
        }
        
    }

    public getNameDoc():string{
        return this.rucEmisor+"-"+this.fechaDoc+"-"+this.rucReceptor+"-"+this.documento+"-"+this.serie+"-"+this.numero.padStart(8,'0');
    }

    public getValidation():boolean{
        if((this.rucEmisor!=undefined && this.rucEmisor!=null && this.rucEmisor.trim()!="") &&
        (this.documento!=undefined && this.documento!=null && this.documento.trim()!="") &&
        (this.serie!=undefined && this.serie!=null && this.serie.trim()!="") &&
        (this.numero!=undefined && this.numero!=null && this.numero.trim()!="") &&
        (this.date!=undefined && this.date!=null))
        {
            return true;
        }else{
            return false;
        }
    }
}
