import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  baseUrlString: string;
  constructor(private http:HttpClient) { 
    //this.baseUrlString="http://localhost:56589/Api/";
    this.baseUrlString="https://gdriveapi.posigesa.net.pe/Api/";
  }
  
  searchFiles(name:string): Observable<any>{
      return this.http.get(this.baseUrlString+"ApiGDrive/?name="+name);
  }

  downloadFile(id:string): Observable<any>{
		return this.http.get(this.baseUrlString+"ApiGDrive/"+id,{ responseType: 'blob' });
  }

  downloadCDR(nombre:string){
    return this.http.get(this.baseUrlString+"CDRDownload/?nombre="+nombre,{ responseType: 'blob' });
  }
}
